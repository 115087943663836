import * as React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Layout from '../../components/Layout'
import banner_1_0 from '../../images/news/expo2020/banner_1_0.jpg'
import banner_1_1 from '../../images/news/expo2020/banner_1_1.jpg'
import banner_1_2 from '../../images/news/expo2020/banner_1_2.jpg'
import img_2_0 from '../../images/news/expo2020/img_2_0.jpg'
import img_2_1 from '../../images/news/expo2020/img_2_1.jpg'
import img_2_2 from '../../images/news/expo2020/img_2_2.jpg'
import img_3_0 from '../../images/news/expo2020/img_3_0.jpg'
import img_3_1 from '../../images/news/expo2020/img_3_1.jpg'
import img_3_2 from '../../images/news/expo2020/img_3_2.jpg'


const lorem = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. "

export default function PostPage() {
    return (
        <Layout title='Conex at Expo 2020 | Conex Research'>
            <section className='w-screen h-fit mt-6 mb-20'>

                <div className='px-8 lg:px-20'>

                    <h5 className='mb-10 sm:hidden'><a href='/media'>{'< '}Back to News</a></h5>
                    <h3 className='text-xl ws:text-3xl font-light text-slate-700 mb-3'>26 Feb 2022</h3>
                    <h1 className='text-5xl sm:text-7xl ws:text-8xl font-semibold mb-3'>Conex at Expo 2020</h1>
                    <h2 className='text-xl ws:text-3xl sm:text-2xl mb-3'>Visiting the 'World's Greatest Show'</h2>

                    <div className='grid grid-rows-1 grid-cols-2'>

                        <h3 className='font-light text-slate-700 justify-self-start ws:text-2xl'>By <a className='text-conex-blue' href='https://twitter.com/TomDixonDesign' target='_blank'>Tom Dixon</a></h3>

                        <div className='w-full flex flex-row justify-end gap-3'>

                            <a href={'https://twitter.com/intent/tweet?url=https%3A%2F%2Fconexresearch.com%2Fnews%2Fexpo2020&via=ConexResearch&text=Conex%20at%20Expo%202020'} title="Share on Twitter" target='_blank' className="flex items-center p-1">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5 text-conex-blue">
                                    <path d="M23.954 4.569a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.061a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.937 4.937 0 004.604 3.417 9.868 9.868 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 002.46-2.548l-.047-.02z"></path>
                                </svg>
                            </a>

                        </div>
                        
                    </div>

                </div>

                <div className='w-full h-fit mt-20'>
                    
                    <Carousel autoPlay={true} infiniteLoop={true} interval={4000} transitionTime={1000} showThumbs={false} showStatus={false}>
                        <div>
                            <img src={banner_1_0}/>
                        </div>
                        <div>
                            <img src={banner_1_1}/>
                        </div>
                        <div>
                            <img src={banner_1_2}/>
                        </div>
                    </Carousel>

                </div>

            </section>

            <section className='w-screen max-w-full'>

                <p className='font-light text-lg ws:text-3xl leading-relaxed px-8 lg:px-64'>

                    <strong className='font-semibold'>Background</strong><br/>

                    Conex Research's visit to the United Arab Emirates back in October for the 72nd International Astronautical Congress saw many fascinating displays and initiatives.
                    One of the most impressive was the long-awaited Expo 2020 hosted in Dubai.
                    The World Expo is held every 5 years, and this year – following a coronavirus-related suspension - runs from the 1st October 2021 to the 31st of March 2022.
                    Central to the Expo park is the Al Wasl dome; the world's largest 360-degree projection surface. Situated around the dome are pavilions, all with uniquely designed architecture to represent a specific theme or country.
                    The global event has been hailed as the most diverse Expo yet, featuring the highest turnout of countries recorded. 


                    <br/><br/><br/><strong className='font-semibold'>Our Arrival</strong><br/>

                    The Conex team traveled to The Expo several times during the week of the IAC, visiting the technology park via Dubai's specially-extended metro line directly channeling visitors to the park.
                    Upon arrival we were greeted by a spectacular skyline, featuring the iconic dome and surrounding surreal architecture. Entering through the station, we were greeted by immense towering architecture featuring facades of color.
                    A boulevard leading straight into the curving open arches of the dome was flanked by two gardens filled with flags of the participating countries.
                    The atmosphere was electric with excitement as visitors were welcomed by a variety of Expo 2020 host robots readying us to partake in our venture across the park.
                    

                    <br/><br/><br/><strong className='font-semibold'>The Al Wasl Dome</strong><br/>

                    Proceeding towards the dome was a surreal experience. The sound of the ongoing performances grew with each step as we neared the atrium; a dreamlike environment with ceiling projections providing a dramatically dynamic visual ambiance.
                    The tesselating petals of the dome used for partial shade by day would light up with erupting colors and animations playing in concert with the unfolding performance. The Al Wasl dome was also a stunning feat of art and engineering. It stands at 67.5 meters (221 feet) tall and has a 130 meter (427 feet) diameter featuring a 550-tonne steel crown.
                    It sits atop Al Wasl Plaza, the beating heart of the Expo 2020 site. Al Wasl, which means “the connection” in Arabic, is emblematic of the World Expo’s theme: ‘Connecting Minds, Creating the Future'. A total of 252 laser projectors were installed in 42 projector pods located around the inside perimeter of the dome.
                    Located in the center of the dome was a stage where displays and performances would take place.
                    Each day of the Expo was presented by a different country where dances, and spectacles of the cultures were showcased. 
                    
                </p>

                <div className='flex flex-col md:flex-row gap-10 md:gap-10 lg:gap-20 p-10 md:p-8 lg:p-16 basis-1/3'>
                    <div>
                        <img src={img_2_0} className='rounded-xl'/>
                    </div>
                    <div>
                        <img src={img_2_1} className='rounded-xl'/>
                    </div>
                    <div>
                        <img src={img_2_2} className='rounded-xl'/>
                    </div>
                </div>

                <p className='font-light text-lg ws:text-3xl leading-relaxed px-8 lg:px-64'>

                    <strong className='font-semibold'>The Robots</strong><br/>

                    Dotted across the Expo 2020 park was a fleet of 162 robots straight out of the Pixar {'&'} Walt Disney Pictures movie WALL-E dedicated to servicing and maintaining the park. They were especially helpful in ensuring the facility's safety in light of the COVID-19 pandemic.
                    Security robots were embedded with facial recognition cameras that could detect if an individual was without a mask. If a visitor was not wearing their mask, (masks were mandatory in all public places) the robot would approach saying "please wear a mask.
                    I am the head of security here". Much to the visitor's amazement and amusement, the novelty became the system’s Achilles' heel as visitors approached the robot purposefully to trigger its protocol.
                    A cute small orange robot called Opti, undoubtedly the mascot of the park, was used to provide visitor information upon request.
                    The robot featured a responsive face that provided gestures combined with warm interactions for visitors.

                    <br/><br/><br/><strong className='font-semibold'>Pavillions</strong><br/>

                    Divided into three districts: Sustainability, Mobility and Opportunity, each with its own Mega Pavilion and a mix of Country Pavilions, Expo 2020 provided a rare opportunity to explore each country's perception of the future.
                    The Expo was a chance for countries to display their visions of technology adoption and cultural enrichment. The US pavilion featured technology and artifacts such as a touchable lunar sample, the first iPhone to ever come off the production line, a Falcon 9 and many more.
                    Space was a major theme across the event as countries across the world are rallying to get a foothold in the commercial aerospace market. Countries like Germany were showcasing their plans to meet the UN Sustainable Development Goals (SDGs).
                    All the pavilions the Conex team visited had an excellent execution in communicating the countries initiatives through interactive displays and exhibitions.  
                                        
                </p>

                <div className='flex flex-col md:flex-row gap-10 md:gap-10 lg:gap-20 p-10 md:p-8 lg:p-16 basis-1/3'>
                    <div>
                    <img src={img_3_0} className='rounded-xl'/>
                    </div>
                    <div>
                    <img src={img_3_1} className='rounded-xl'/>
                    </div>
                    <div>
                    <img src={img_3_2} className='rounded-xl'/>
                    </div>
                </div>

                <p className='font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64'>

                    <strong className='font-semibold'>Remarks</strong><br/>

                    "I was surprised by the sheer scale of the projections on the dome, the vibrant colors and the music! It was like nothing I've ever experienced before, and when lying down I was completely absorbed by the experience.
                    Shows ranged from those on nature to those on society; but one showing a launching rocket and the vastness of space was simply overwhelming.
                    It was a fantastic reminder of how lucky we are to be here - pushing the boundaries of human knowledge." - James McKevitt, Co-Executive Director of Conex Research. The Expo is an amazing initiative of optimism; celebrating technology and human culture.
                    It serves as a beacon of internationalism that is very poignant in the times we live in today. 
                                        
                </p>

            </section>

        </Layout>
    )
}
